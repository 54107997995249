import { useEffect, useState } from "react";
import { deletePolygonById, exportCSV, getTableData, getfarmers, uploadPolygonByCSV } from "../../../APIs/apiCalls";
import MaterialReactTable from "material-react-table";
import { useNavigate } from "react-router-dom";
import { mkConfig, generateCsv, download } from "export-to-csv";
import AlertConfirm from "react-alert-confirm";
import { NotificationManager } from "react-notifications";
import Spinners1 from "../../../Components/Spinners/Spinners1";
import CSVErrorMessages from "../../../Components/CSVErrorMessages/CSVErrorMessages";
import { handleKMLDownload, isFileCSV } from "../../../Utils/commonFunctions";
import { fetchUrl } from "../../../APIs/server";

const url_ = fetchUrl;

const PolygonTable = () => {
    const navigate = useNavigate();
    const [success,setSuccess] = useState('');
    const [pending,setPending] = useState('');
    const userType = localStorage.getItem('type');
    const [disabled, setDisabled] = useState(false);

    const [fileUploadingErrors, setFileUploadingErrors] = useState([]);
    const [data, setData] = useState([]);
    const [flag, setFlag] = useState(false);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);

    //table state
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');

   
    const columns = [
        {
            
            header: 'FARMER NAME',
            accessorKey : 'farmername',
            Cell : (data) => {
                const {farmername, _id} = data.row.original;
                return (
                    <div style={{color : "blue", textDecoration : 'underline', cursor : 'pointer', textTransform : 'uppercase'}} ><a href={`/#/farmer-profile/${_id}`} target="_blank">{`${farmername}`}</a></div>
                )
            },
        },
        {
            accessorKey: 'guardian',
            header: 'Guardian Name',
            Cell : ({cell}) => {
                return <>{cell.getValue().toUpperCase()}</>
            }
        },
        {
            accessorKey: 'isAWDFarmer',
            header: 'AWD Farmer',
            Cell : ({cell}) => {
                return <>{cell.getValue() === true ? 'TRUE' : 'FALSE'}</>
            }
        },
        {
           
            accessorKey : 'polygon',
            header : 'Status',
            filterVariant: 'select',
            filterSelectOptions: ['success', 'pending'],
            Cell : ({row}) => {
                const status = row.original?.polygon?.length>0 ? 'success' : 'pending';
                return <>{ status === 'pending' ? <span className="badge bg-danger text-white">{status?.toUpperCase()}  <i className="typcn typcn-warning"></i></span> : <span className="badge bg-success text-white">{status?.toUpperCase()} <i className="typcn typcn-input-checked"></i></span>}</>
            }
        },
        {
            accessorKey: 'district',
            header: 'DISTRICT',
            Cell : ({cell}) => {
                const val = cell.getValue();
                return <>{val!=undefined ? val.toUpperCase() : <span className="text-danger">NA</span>}</>
            }
        },
        {
            accessorKey: 'tehsil',
            header: 'TEHSIL',
            Cell : ({cell}) => {
                const val = cell.getValue();
                return <>{val!=undefined ? val.toUpperCase() : <span className="text-danger">NA</span>}</>
            }
        },
        {
            accessorKey: 'block',
            header: 'BLOCK',
            Cell : ({cell}) => {
                const val = cell.getValue();
                return <>{val!=undefined ? val.toUpperCase() : <span className="text-danger">NA</span>}</>
            }
        },
        {
            accessorKey: 'village',
            header: 'VILLAGE',
            Cell : ({cell}) => {
                const val = cell.getValue();
                return <>{val!=undefined ? val.toUpperCase() : <span className="text-danger">NA</span>}</>
            }
        },
        {
            
            header: 'TOTAL AREA COVERED',
            Cell : ({row}) => {
                const polygons = row.original.polygon;
                const totalArea = polygons.reduce((accumulator, currentValue) => {
                    return accumulator + currentValue.area;
                }, 0);
                return (
                    <>{(totalArea * 0.00024711).toFixed(2)} Acres</>
                );

            }
        },
        {
            header: 'Polygon Details',
            Cell : ({row}) => {
                const polygons = row.original.polygon;
                return (
                    <>{ polygons === undefined || polygons.length === 0 ? <>NA</> 
                    : <> {polygons.map((polygon,index) => <div key={index}>
                            <p style={{textDecoration : 'underline', fontWeight : 'bold'}}>Polygon {index+1} - </p>
                            <p>Area Covered : {(polygon.area * 0.00024711).toFixed(2)} ac </p>
                            {/* <p>Mode : {polygon.mode.toUpperCase()} </p> */}
                            <a className="btn btn-sm btn-info"  href={`/#/view/${polygon._id}`}  target="_blank"><i className="typcn typcn-export"></i></a>
                            <button className="btn btn-sm btn-primary ml-1" onClick= {() => handleDownload(polygon)}><i className="typcn typcn-download"></i></button>
                            {
                                userType && userType != 'viewer' && <button className="btn btn-sm btn-danger ml-1"  onClick={()=> handleCancelRowEdits(polygon._id)}><i className="typcn typcn-trash"></i></button>
                            }
                            <hr/>
                        </div>)}
                      </>
                    }</>
                );
            }
        },
    ];
    
    const handleCancelRowEdits = (id) => {
      
        AlertConfirm({
            title: 'Are you sure?',
            desc: 'This cannot be undone.',
            onOk: () => {
                
                deletePolygonById(id).then((response) => {
                    // console.log(response);
                    if(response.result === 'success'){
                        setFlag(!flag);
                        NotificationManager.success('Deleted the Record successfully.')
                    }
                })
            },
            onCancel: () => {
              // console.log('cancel');
            }
        });
    };
    
    const handleExportData = async() => {
        try {
            const export_url = new URL(`${url_}/export-polygon`);
            for(let item of columnFilters){
                export_url.searchParams.set(item.id, item.value)
            }
            const blob = await exportCSV(export_url.href);
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Polygons.csv');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
        console.error('Error downloading CSV:', error);
        }
    };
    const csvUploadHandler = (event) => {
        setFileUploadingErrors([]);
        const files = event.target.files[0];
        if(files){
            if(isFileCSV(files) === false){
                return;
            }
            let formData = new FormData();
            formData.append('file', files);
            setDisabled(true);
            uploadPolygonByCSV(formData).then((response) => {
                // console.log(response);
                setDisabled(false);
                setFlag(!flag);
                if(response.result ==='success'){
                    NotificationManager.success("CSV File uploaded successfully.");
                    setFileUploadingErrors(response.errors);
                }
            }).catch((error) => {
                setDisabled(false);
                NotificationManager.error("Unable to Process CSV file. Please check the headers.");
            })
            
            event.target.value = "";
        }
    };
    const handleDownload = (data) => {
        // console.log(data);
        const filename = `${data.farmername}_${data.village}_${data.block}_${data.tehsil}_${data.district}.kml`;
        handleKMLDownload(data, filename);
    }
    useEffect(() => {
        (async() => {

            const url = new URL(`${url_}/manage-polygon`)
            url.searchParams.set('page', pagination.pageIndex + 1);
            url.searchParams.set('limit', pagination.pageSize);

            for(let item of columnFilters){
                url.searchParams.set(item.id, item.value)
            }

            if(globalFilter.length > 0) {
                url.searchParams.set('farmername', globalFilter)
            }
            
            setIsRefetching(true)
            let response = await getTableData(url);
            setIsRefetching(false)
            let data = response.data;
            let count = response.count;
            if(data){
                let success = response.success;
                setSuccess(success);
                setPending(count-success);
            }
            setRowCount(response.recordCount);
            
            setData(data);
        })();
    }, [
        columnFilters,
        globalFilter,
        pagination.pageIndex,
        pagination.pageSize,
        flag
    ]);
    if(data === undefined) {
        return <Spinners1/>
    }
    return (
        <div>
            <p>{success} RECORDS <span className="text text-success">UPLOADED <i className="typcn typcn-input-checked"></i></span></p>
            <p>{pending} RECORDS <span className="text text-danger">PENDING <i className="typcn typcn-warning"></i> </span></p>
            <CSVErrorMessages errors={fileUploadingErrors}/>
            <MaterialReactTable
                columns={columns}
                data = {data}
            
                muiTableProps={{
                    sx: {
                    border: '1px solid rgba(81, 81, 81, 1)',
                    },
                }}
                muiTableHeadCellProps={{
                    sx: {
                        border: '1px solid rgba(81, 81, 81, 1)',
                    },
                }}
                muiTableBodyCellProps={{
                    sx: {
                        border: '1px solid rgba(81, 81, 81, 1)',
                    },
                }}
                renderTopToolbarCustomActions={({table}) => (
                    <div>
                        {
                            userType!='viewer' ? 
                            <><button 
                            className='btn btn-sm btn-bluish-green btn-icon-prepend btn-icon-text'
                            onClick={() => navigate('/polygon-mapping')}
                            >
                        New <i className='typcn typcn-plus'></i>
                            </button>
                            &nbsp;</> : null
                        }
                        {/* {
                            data.length !=0 ? 
                            <><button
                                className='btn btn-sm btn-primary btn-icon-prepend btn-icon-text'
                                onClick={() => handleExportData()}
                                >
                                Export CSV <i className='typcn typcn-download'></i>
                            </button> &nbsp;</> : ''
                        } */}
                        
                        {/* <button className="btn btn-sm btn-primary btn-icon-prepend btn-icon-text"
                        disabled={
                            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                        }
                        onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                        >
                                Export Selected Rows <i className='typcn typcn-download'></i>
                        </button> &nbsp; */}

                        {/* <label htmlFor="fileUpload" className="file-upload btn-sm btn-primary" style={{cursor : disabled === true ? 'auto' : 'pointer'}}><i className='typcn typcn-cloud-storage btn-icon-prepend' style={{fontSize : '16px'}}></i> Upload CSV
                            <input id="fileUpload" style={{display : 'none'}} type="file" onInput={(event) => csvUploadHandler(event)} disabled={disabled}/>
                        </label> */}
                    </div>
                    
                )}
                
                muiTablePaginationProps={{
                  showFirstButton: true,
                  showLastButton: true,  
                }}
               
                options={{
                    exportButton: true
                }}

                manualPagination={true}
                onPaginationChange={setPagination}
                manualFiltering = {true}
                onColumnFiltersChange={setColumnFilters}
                
                state={{
                    pagination,
                    showProgressBars: isRefetching,
                }}
                rowCount={rowCount}
                
                onGlobalFilterChange = {setGlobalFilter}
            />
        </div>
    );
}

export default PolygonTable;