import { PDFDownloadLink } from "@react-pdf/renderer";
import PDFContent from "../Land Records/AddLandRecords/LandRecordForm/LeaseAgreementDownload/PDFContent";

const LandRecords = ({landrecords, data, undertaking, leasedAgreement}) => {
    return (
        <div className="card" style={{fontStyle : 'italic', textTransform : 'capitalize'}}>
            <div className="row">
                <div className="col-md-12 mt-3">
                    <div className={`p-3`}>
                                <h5 className="text-info">Land Records</h5>
                                <p>Owned Land Khasra Numbers :{ data.ownedLandKhasraNumbers.length!=0 ? data.ownedLandKhasraNumbers.join(', ') : <span className="text-danger"> No khasra Number is uploaded</span> }</p>
                                <p>Leased Land Khasra Numbers :{data.leasedLandKhasraNumbers.length!=0 ? data.leasedLandKhasraNumbers.join(', ') : <span className="text-danger"> No khasra Number is uploaded</span> }</p>
                                <br/>       
                                <h6 style={{textDecoration : 'underline'}}>Land Documents</h6>
                                {
                                    landrecords.length!=0 ? 
                                    <>
                                    {
                                        landrecords.map((land, index) => 
                                            <div key={index} style={{backgroundColor : '#F8F8F8', marginTop : '10px', padding : '10px'}}>
                                                <p>Record - {index + 1}</p>
                                                <p>Khasra Number - {land.khasraNumbers}</p>
                                                <p><a href={land.filename} target="_blank">View Land Document <i className="typcn typcn-export"></i> </a></p>
                                            </div>
                                        )
                                    }
                                    </> : <span className="text-danger">Land Documents are pending.</span> 
                                }             
                    </div>
                </div>
                <div className="col-md-12 mt-3">
                    <div className=" p-3">
                        <h5 className="text-info">Undertaking And Leased Documents Records.</h5>
                        {
                            undertaking!=null ? <div  style={{backgroundColor : '#F8F8F8', marginTop : '10px', padding : '10px'}}> 
                                <h6 style={{textDecoration : 'underline'}}>Undertaking Document</h6>
                                <p>Status : <span className='badge bg-success text-white'>UPLOADED  <i className='typcn typcn-input-checked'></i> </span></p>
                                <p><a href={undertaking.file} target='_blank'>View Undertaking Document <i className='typcn typcn-export'></i> </a></p>
                                
                            </div> : <p className='text-danger mt-3'><i className='typcn typcn-warning'></i>  Undertaking Agreement is not uploaded.</p>
                        }

                        {
                            leasedAgreement!=null ? <div  style={{backgroundColor : '#F8F8F8', marginTop : '10px', padding : '10px'}}> 
                                <h6 style={{textDecoration : 'underline'}} >Leased Document</h6>
                                <p>Status : <span className='badge bg-success text-white'>UPLOADED  <i className='typcn typcn-input-checked'></i> </span></p>
                                {
                                    leasedAgreement.map((item, index) => 
                                    <div>
                                        <p>{index+1} . Khasra Numbers : {item.khasraNumbers.join(', ')}, Area : {item.area} Acres &nbsp;
                                        
                                        <PDFDownloadLink key={index} document={<PDFContent leasedAgreement = {item} />} fileName={`${item.farmerName}_${item.farmerVillage}_${item.farmerBlock}_${item.farmerTehsil}_${item.farmerDistrict}.pdf`}>
                                                {
                                                    ({ blob, url, loading, error }) => loading ? 'Loading document...' : <button className="btn btn-sm btn-primary"><i className="typcn typcn-download btn-icon-prepend"></i> </button> 
                                                }
                                        </PDFDownloadLink> </p> 
                                        {/* &nbsp;
                                        <button className='btn btn-sm btn-success' disabled={disabled2}><i className="typcn typcn-edit btn-icon-prepend"></i> Edit</button> */}
                                    
                                        <hr/>
                                    </div>)
                                }
                            
                            </div> : <p className='text-danger mt-3'><i className='typcn typcn-warning'></i>  Leased Agreement is not uploaded.</p>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LandRecords;