import { useEffect, useState } from "react";
import { NotificationContainer, NotificationManager } from "react-notifications";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useFormik } from "formik";
import validate from "./validate";
import { addLandRecords } from "../../../../APIs/apiCalls";
import styles from './LandRecordForm.module.css'
import SubmitButton from "../../../../Components/Buttons/SubmitButton";

const LandRecordForm = (props) => {
    const data = props.data;
    const [disabled, setDisabled] = useState(false);
    const animatedComponents = makeAnimated();
    const [selectVal, setSelectVal] = useState([]);

    const acceptedMimeTypes = ['application/pdf'];
    const maxFileSize = process.env.REACT_APP_FILE_MAX_SIZE; //in kbs

    //change select value in form and update variable.
    const selectHandler = (event) => {
        let arr = [];
        setSelectVal(event);
        for(let item of event){
            const val  = item.value;
            arr.push(val);
        }
        formik.setFieldValue('khasraNumbers', arr);
        // console.log(arr);
    }

    

    const resetForm = () =>{
        //reset form values
        formik.resetForm({
            khasraNumbers : [],
            landDocument : '',
            area : '',
        });

        //select values not changed. change that
        setSelectVal([]);
        //reset the form.
        document.getElementById('myForm').reset();
    }

    const formik = useFormik({
        initialValues : {
            khasraNumbers : [],
            landDocument : '',
            area : '',
        },
        validate,
        onSubmit : (values) => {
            // console.log(values)
                let formData = new FormData();
                // create to formdata to process files
                // console.log(values.landDocument);
                formData.append('khasraNumbers', values.khasraNumbers);
                formData.append('area', values.area);
                formData.append('landDocument', values.landDocument);
                formData.append('farmerId', data._id);
                formData.append('farmername', data.firstname + ' ' + data.lastname);
                setDisabled(true);

                //send form data to backend.
                addLandRecords(formData).then((response) => {
                    // console.log(response);
                    
                    if(response.result === 'success'){
                        //sends land records and farmer data
                        props.changeRecords(response.data, data);
                        resetForm();
                        NotificationManager.success('Success', 'Uploaded Land Details Successfully', 1000);
                    }
                    setDisabled(false);
                });
        }
    })

    const fileChangeHandler = async(event) => {
        const file = event.target.files[0];

        if(file){
           
            const {size, type} = file;
            const fileSizeInKB = size / 1024;

            if(acceptedMimeTypes.includes(type) === false){
                
                formik.setFieldError('landDocument',`File extension should be pdf.`);
            }else if(fileSizeInKB > maxFileSize){
                formik.setFieldError('landDocument',`Maximum size limit is ${maxFileSize/1024}mb`);
            }else{
                formik.setFieldValue('landDocument', file)
            }
           
        }
    }

    useEffect(()=>{ 
    },[]);

    return (
        <div>
            
            <h4>Land Records Form</h4>
            <form id="myForm" className="p-0" onSubmit={formik.handleSubmit}>
                <div className="col-md-12 mt-2 pt-3">
                    <div>
                        <Select
                        id="khasraNumbers"
                        className={formik.touched.khasraNumbers && formik.errors.khasraNumbers ? styles.formInputError : ''}
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        
                        placeholder = "Select Khasra Numbers"
                        onChange={(event) => selectHandler(event)}
                        onBlur={formik.handleBlur}
                        options={props.khasraNumbers}
                        value={selectVal}
                        /> 
                        
                        {formik.touched.khasraNumbers && formik.errors.khasraNumbers ? <div className={styles.error}>{formik.errors.khasraNumbers}</div> : ""}
                    </div>
                    
                    <div>
                        <input type="file"
                        id="landDocument"
                        className={`form-control mt-2 ${formik.touched.landDocument && formik.errors.landDocument ? styles.formInputError : ''}`}
                        onChange={(event) => fileChangeHandler(event)}
                        onBlur={formik.handleBlur}
                        />
                        {formik.touched.landDocument && formik.errors.landDocument ? <div className={styles.error}>{formik.errors.landDocument}</div> : ""}
                    </div>

                    <div>
                        <input type="number"
                        id="area" placeholder="Area in acres"
                        className={`form-control mt-2 ${formik.touched.area && formik.errors.area ? styles.formInputError : ''}`}
                        onChange={formik.handleChange}
                        min={0}
                        step='any'
                        onBlur={formik.handleBlur}
                        />
                        {formik.touched.area && formik.errors.area ? <div className={styles.error}>{formik.errors.area}</div> : ""}
                    </div>

                    <div className="mt-2">
                        {
                            formik.values.landDocument != '' ? <iframe src={URL.createObjectURL(formik.values.landDocument)} width="100%"></iframe> : ''
                        }
                    </div>
                    
                    <SubmitButton disabled={disabled}/> &nbsp;
                    <button type="reset" id="resetbtn" className="btn btn-danger mt-2" onClick={resetForm}>Reset</button>
                </div>
            </form>
            <NotificationContainer/>
        </div>
    );
}

export default LandRecordForm;
