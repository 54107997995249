import { Button,  Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { useFormik } from 'formik';
import styles from './../ManageMembers/ManageMembers.module.css';
import { useEffect, useState } from 'react';
import validate from './validate';
import { getFeildTeamById, getLocation, updateFeildTeamRecord } from '../../../APIs/apiCalls';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { NotificationManager } from 'react-notifications';
import Location from './Location';
import AddedItems from '../../FeildInformation/AddFeildInformation/FeildForm/AddedItems';

const projectList = [
  {
    label : 'Alternate Wetting and Drying (AWD)',
    value : 'Alternate Wetting and Drying (AWD)'
  }
];

export const CreateNewAccountModal = ({ open, onClose, onsubmit, id }) => {  
   
    const [location, setLocation] = useState([]);
    const animatedComponents = makeAnimated();
    const [selectVal, setSelectVal] = useState([]);

    const formik = useFormik({
      initialValues : {
        firstname : '',
        lastname : '',
        type : '',
        email : '',
        dob : '',
        locations : [],
        projects : []
      },
      validate : validate,
      onSubmit : (values) => {
        console.log(values)
        onsubmit(values);
      }
    });

    const addLocationHandler = (values) => {
      let arr = formik.values.locations;
      console.log(formik.values.locations)
     console.log(arr);
      if(arr.includes(values) === false){
        arr.push(values);
        formik.setFieldValue('locations', arr);
        return;
      }
      NotificationManager.error('Village is already added')
    }

    //change select value in form and update variable.
    const selectHandler = (event) => {
      let arr = [];
      setSelectVal(event);
      for(let item of event){
          const val  = item.value;
          arr.push(val);
      }
      formik.setFieldValue('projects', arr);
      // console.log(arr);
  }

    useEffect(() => {
      getLocation().then((response) => {
        setLocation(response.data);
        // const villages = response.data.map(item => item.village).sort();
        // setLocation(response.data)
      })
      formik.resetForm({});
      if(id!="add"){
        getFeildTeamById(id).then((response) =>{ 
          console.log(response.data);
          formik.setValues(response.data);
          if(response.data.locations === undefined){
            formik.setFieldValue('locations', []);
          }
          if(response.data.projects!=undefined){
            console.log("hit-1")
            let arr = [];
            for(let project of response.data.projects){
              arr.push({label : project, value : project});
            }
            setSelectVal(arr);
          }else{
            formik.setFieldValue('projects', []);
          }
        })
      }else{
        formik.resetForm({});
      }

    },[open]);
    
  
    return (
      <Dialog open={open}>
        <DialogTitle textAlign="center">Add Users</DialogTitle>
       
        <DialogContent>
          {
            id==='add' || formik.values.firstname ? 
              <form onSubmit={formik.handleSubmit}>
                <div className='row'>
                <Stack
                sx={{
                  width: '100%',
                  minWidth: { xs: '280px', sm: '360px', md: '400px' },
                  gap: '1.5rem',
                }}
              >
                  <div className='col-md-12'>
                      
                      <div className='form-group'>
                          <input className={`form-control ${  formik.touched.firstname === true && formik.errors.firstname ? styles.formInputError : ""}`}
                          value={formik.values.firstname}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          id='firstname' placeholder='Enter firstname'/>
                          {formik.touched.firstname === true && formik.errors.firstname ? <div className={styles.error}>{formik.errors.firstname}</div> : ""}
                      </div>
                      <div className='form-group'>
                          <input className={`form-control ${ formik.touched.lastname === true && formik.errors.lastname ? styles.formInputError : ""}`}
                          value={formik.values.lastname}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          id='lastname' placeholder='Enter lastname'/>
                          {formik.touched.lastname === true && formik.errors.lastname ? <div className={styles.error}>{formik.errors.lastname}</div> : ""}
                      </div>
                      <div className='form-group'>
                          <select className={`form-control ${ formik.touched.type === true && formik.errors.type ? styles.formInputError : ""}`}
                          value={formik.values.type}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          id='type'>
                            <option value=''>Select Role</option>
                            <option value='admin'>Admin</option>
                            <option value='editor'>Editor</option>
                            <option value='viewer'>Viewer</option>
                          </select>
                          {formik.touched.type === true && formik.errors.type ? <div className={styles.error}>{formik.errors.type}</div> : ""}
                      </div>

                      <div className='form-group'>
                          <input className={`form-control ${ formik.touched.email === true && formik.errors.email ? styles.formInputError : ""}`}
                          id='email' placeholder='Enter Email'
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}/>
                          {formik.touched.email === true && formik.errors.email ? <div className={styles.error}>{formik.errors.email}</div> : ""}
                      </div>

                      <Select
                        id="projects"
                        className={formik.touched.projects && formik.errors.projects ? styles.formInputError : ''}
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        placeholder = "Select Projects"
                        onChange={(event) => selectHandler(event)}
                        onBlur={formik.handleBlur}
                        options={projectList}
                        value={selectVal}
                        /> 
                        
                        {formik.touched.projects && formik.errors.projects ? <div className={styles.error}>{formik.errors.projects}</div> : ""}

                    <div className='mt-3'>
                      <Location location={location} add={(values) => addLocationHandler(values)}/>
                    </div>
                    
                    <AddedItems items = {formik.values.locations} change={(fieldName, value) => formik.setFieldValue(fieldName, value)} />
                      <div className='form-group mt-3'>
                          <input className={`form-control ${ formik.touched.dob === true && formik.errors.dob ? styles.formInputError : ""}`}
                          type='date' id='dob' placeholder='Enter Date Of birth'
                          value={formik.values.dob}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}/>
                          {formik.touched.dob === true && formik.errors.dob ? <div className={styles.error}>{formik.errors.dob}</div> : ""}
                      </div>
                  </div>
                </Stack>
              </div>
              <DialogActions sx={{ p: '1.25rem' }}>
                  <Button onClick={onClose}>
                    Cancel
                  </Button>
                  <Button  color="success" variant="contained" type='submit'>
                      Save
                  </Button>
              </DialogActions>
          </form> : 'Loading...'
          }
       
        </DialogContent>
        
      </Dialog>
    );
  };
  