import { useEffect, useState } from "react";
import { deleteLandRecords, getAllLandRecords, getLandRecords, getTableData, getfarmers } from "../../../../APIs/apiCalls";
import MaterialReactTable from "material-react-table";
import { useNavigate } from "react-router-dom";
import landImage from '../../../../assets/img/land.png';
import { mkConfig, generateCsv, download } from "export-to-csv";
import Spinners1 from "../../../../Components/Spinners/Spinners1";
import { downloadFile } from "../../../../Utils/commonFunctions";
import { NotificationManager } from "react-notifications";
import AlertConfirm from "react-alert-confirm";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PDFContent from "../../AddLandRecords/LandRecordForm/LeaseAgreementDownload/PDFContent";
import { fetchUrl } from "../../../../APIs/server";

const csvConfig = mkConfig({ useKeysAsHeaders: true });
const url_ = fetchUrl;

const userType = localStorage.getItem('type');
const LandRecords = () => {
    const navigate = useNavigate();
    const [data, setData] = useState(undefined);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    const [success, setSuccess] = useState(0);

    //table state
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    
    const columns = [
        {
            accessorKey: 'farmername',
            header: 'Farmer Name',
            Cell : (data) => {
                const {_id, farmername} = data.row.original;
                return (
                    <a style={{color : "blue", textDecoration : 'underline', cursor : 'pointer', textTransform : 'uppercase'}} target="_blank" href={`/#/farmer-profile/${_id}`}>{farmername}</a>
                )
            }
        },
        {
            accessorKey: 'guardian',
            header : 'Guardian Name',
            Cell : ({cell}) => {
                const record = cell.getValue().toUpperCase();
                return <div>{record}</div>
            }
        },
        {
            accessorKey: 'isAWDFarmer',
            header : 'IS AWD FARMER',
            Cell : ({cell}) => {
                const record = cell.getValue();
                return <div>{record === true ? 'TRUE' : 'FALSE'}</div>
            }
        },
        {
            accessorKey: 'lands',
            header : 'Status',
            filterVariant: 'select',
            filterSelectOptions: ['success', 'pending'],
            filterFn: (row, id, filterValue) => {
                const value = row.original.lands;
                if (filterValue === 'success') {
                    return value !== undefined && value.length > 0;
                }
                if (filterValue === 'pending') {
                    return value === undefined || value.length === 0;
                }
                return true;
            },
            Cell : ({cell}) => {
                const record = cell.row.original.lands;
                return <div>{record !== undefined && record.length >0  ? <span className="badge bg-success text-white"> SUCCESS <i className="typcn typcn-input-checked"></i></span> : <span className="badge bg-danger text-white">PENDING <i className="typcn typcn-warning"></i></span>}</div>
            }
        },
       
        {
            header: 'Uploaded Land Documents',
            Cell: ({ cell }) => {
                const data = cell.row.original;
                let uploaded = [...data.lands.map((land) => [...land.khasraNumbers])];
                console.log(uploaded); 
                const khasraNumbers = [...data.leasedLandKhasraNumbers, ...data.ownedLandKhasraNumbers];

                return (
                    <div>
                        {khasraNumbers.length === 0 ? 'No Khasra number is entered' : uploaded.length === 0 ? 'No documents are uploaded' : uploaded.join(', ') }
                    </div>
                );
            }
        },
        {
            header: 'Pending Land Documents',
            Cell: ({ cell }) => {
                const data = cell.row.original;
                let uploaded = [];
                for(let land of data.lands){
                    uploaded.push(...land.khasraNumbers);
                }
                const khasraNumbers = [...data.leasedLandKhasraNumbers,...data.ownedLandKhasraNumbers];
                
                const pending = khasraNumbers.filter((item) => uploaded.includes(item) === false);
                return (
                    <div>
                        {khasraNumbers.length === 0 ? 'No Khasra number is entered' : pending.length === 0 ? 'No pending land documents.' : pending.join(', ')}
                    </div>
                );
            }
        },
        {
            
            header: 'Statistics',
            Cell: ({ cell }) => {
                const data = cell.row.original;
                let uploaded = [];
                for(let land of data.lands){
                    uploaded.push(...land.khasraNumbers);
                }
                const khasraNumbers = [...data.leasedLandKhasraNumbers,...data.ownedLandKhasraNumbers];

                return (
                    <div>
                        <span>{uploaded.length} Success✅ </span>, <br/>
                        <span>{khasraNumbers.length - uploaded.length} Pending⚠️</span>
                    </div>
                );
            }
        },
       {
        header : 'Total area covered',
        Cell : ({cell}) => {
            const {lands}  =  cell.row.original;
            let totalarea=0;
            
            for(let land of lands){
                totalarea =  totalarea + parseInt(land.area);
            }
            return `${totalarea} Acres`;
        }
       },
        {
            header: 'Land Documents',
            Cell: ({ cell }) => {
                const record = cell.row.original;
                return (
                    <div>
                        {
                            record.lands.length != 0 ? 
                            <>
                            {
                                record.lands?.map((item, index) => 
                                    <div key={index}>
                                        
                                        <p>Khasra Numbers : {item.khasraNumbers.join(', ')}</p>
                                        <p>Area : {item.area} Acres</p>
                                        <button className="btn btn-sm btn-info" onClick={() => downloadFile(item.filename)}> <i className="typcn typcn-download"></i></button>&nbsp;
                                        <a className="btn btn-sm btn-primary" href={item.filename} target="_blank"> <i className="typcn typcn-export"></i></a>
                                       
                                        {  index!=record.lands.length - 1 ? <hr/> : null }
                                    </div>
                                )
                            }</> : 'No Documents are uploaded'
                        }
                        
                    </div>
                );
            }
        },

        {
            header : 'Undertaking Documents',
            Cell: ({ cell }) => {
                const record = cell.row.original;
                return (
                    <div>
                        {
                            record.undertakingDocument != undefined ? 
                            <> { <a href={record.undertakingDocument.file} target="_blank">View Document <i className="typcn typcn-export"></i></a> }</> 
                            : <p className="text-danger">Undertaking document is not uploaded</p> 
                        }
                        
                    </div>
                );
            }
        },
        {
            header : 'Leased Agreements',
            Cell: ({ cell }) => {
                const record = cell.row.original;
                return (
                    <div>
                        {
                            record.leasedAgreement && record.leasedAgreement.length>0 ? 
                            <>
                            {
                                record.leasedAgreement.map((item, index) => <div>
                                    <p key={index}>{index + 1}. Khasra Numbers : {item.khasraNumbers?.join(',')}, Area : {item.area} </p>
                                    <p><PDFDownloadLink document={<PDFContent leasedAgreement = {item} />} fileName={`${item.farmerName}_${item.farmerVillage}_${item.farmerBlock}_${item.farmerTehsil}_${item.farmerDistrict}.pdf`}>
                                            {
                                                ({ blob, url, loading, error }) => loading ? 'Loading document...' : <button className="btn btn-sm btn-primary"><i className = "typcn typcn-download"></i></button> 
                                            }
                                    </PDFDownloadLink>
                                        &nbsp; </p>
                                    <br/>
                                </div>)
                            }</> : <p className="text-danger">Leased Agreement files not uploaded</p>
                        }
                    </div>
                );
            }
        }

    ]

    useEffect(()=>{
        (async() => {
            const url = new URL(`${url_}/manage-land`);
            url.searchParams.set('page', pagination.pageIndex + 1);
            url.searchParams.set('limit', pagination.pageSize);

            for(let item of columnFilters){
                url.searchParams.set(item.id, item.value)
            }

            if(globalFilter.length > 0) {
                url.searchParams.set('farmername', globalFilter)
            }
            
            setIsRefetching(true);
            let response = await getTableData(url.href);
            setIsRefetching(false);
            let data = response.data;
            console.log(data);
            let count = response.count;
            let success = response.success;
            setSuccess(success);
            setData(data);
            setRowCount(count);
        })();
    },[columnFilters,
        globalFilter,
        pagination.pageIndex,
        pagination.pageSize]);
    if(data===undefined){
        return <Spinners1/>
    }
    return (
        <div>
            <p>{success} RECORDS <span className="text text-success">UPLOADED <i className="typcn typcn-input-checked"></i></span></p>
            <p>{rowCount - success} RECORDS <span className="text text-danger">PENDING <i className="typcn typcn-warning"></i> </span></p>
        
        <MaterialReactTable
            columns={columns}
            data = {data}
            muiTableProps={{
                sx: {
                border: '1px solid rgba(81, 81, 81, 1)',
                },
            }}
            muiTableHeadCellProps={{
                sx: {
                    border: '1px solid rgba(81, 81, 81, 1)',
                },
            }}
            muiTableBodyCellProps={{
                sx: {
                    border: '1px solid rgba(81, 81, 81, 1)',
                },
            }}
            renderTopToolbarCustomActions={() => (
                <div>
                    
                    {
                        userType !='viewer' ? 
                        <><button 
                            className='btn btn-bluish-green  btn-icon-text'
                            onClick={() => navigate('/add-landrecords')}
                        >
                            <i className='typcn typcn-plus btn-icon-prepend'></i> <span>New/Edit </span>
                        </button>
                        &nbsp;</> : null
                    }
                    {/* <button
                      className='btn btn-primary  btn-icon-text'
                      onClick={handleExportData}
                    >
                        <i className='typcn typcn-download btn-icon-prepend'></i> <span>Export</span>  
                    </button> */}
                </div>
                
            )}
            muiTablePaginationProps={{
                showFirstButton: true,
                showLastButton: true,
            }}
            options={{
                exportButton: true
            }}

            
            manualPagination={true}
            onPaginationChange={setPagination}
            manualFiltering = {true}
            onColumnFiltersChange={setColumnFilters}
            
            state={{
                pagination,
                showProgressBars: isRefetching,
            }}
            rowCount={rowCount}
            
            onGlobalFilterChange = {setGlobalFilter}
                
        />
        </div>
    );
}

export default LandRecords;