import { useEffect, useState } from "react";
import { deleteLandRecords, getLandRecords } from "../../../../APIs/apiCalls";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { downloadFile } from "../../../../Utils/commonFunctions";

const LandDocumentsList = (props) => {
    // console.log(props.records);
    const userType = localStorage.getItem('type');
    const removeHandler = (id) => {
        // console.log(id)
        deleteLandRecords(id, props.data._id).then(async (response) => {
            // console.log(response);
            if(response.result === 'success'){
                props.changeRecords(response.data, props.data);
                NotificationManager.success('Removed the record successfully', 'Success', 1000);
            }
        })
    }
    return (
        <div >
            <h4>
                Land Records List
            </h4>
            {
                props.records?.length != 0 ? 
                <div>
                <div className="mt-3 mb-3">
                <h5 style={{textDecoration : 'underline'}}> Document Status</h5>
                <p style={{fontWeight : 'bold', fontSize : '15px'}}> <i className="typcn typcn-chevron-right"></i> <span className="text-success" > {props.success.length} khasra number proofs are uploaded <i className="typcn typcn-input-checked" style={{fontSize : '20px'}}></i> </span> </p>
                <p  style={{fontWeight : 'bold', fontSize : '15px'}}> <i className="typcn typcn-chevron-right"></i>  <span className="text-danger">  {props.pending.length} khasra number proofs are pending <i className="typcn typcn-warning" style={{fontSize : '20px'}}></i> </span> </p>
                </div>
                {
                    props.records?.map((item, index) => 
                    <div className="col-md-12 mt-2 p-3" style={{border : '0.5px solid #DCDCDC', borderLeft : '4px solid #198d88', boxShadow:'rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px'}} key={index}>
                        <p>Khasra numbers : {item.khasraNumbers.join(', ')}</p>
                        <p>Area Covered : {item.area} Acres</p>
                        <p>Land Document File : <a href={item.filename} target='_blank'>View Document <i className="typcn typcn-export" style={{fontSize : '20px'}}></i></a> 
                        
                        <button className="btn btn-sm btn-primary btn-icon-append" onClick={() => downloadFile(item.filename)}>Download <i className="typcn typcn-download"></i></button>
                        </p>
                        {
                            userType && (userType === 'admin' ||  userType === 'super-admin') ? <button className="btn btn-danger mt-2" onClick={() => removeHandler(item._id)}>Delete</button> : null
                        }
                        
                    </div>
                    )
                }
                </div> : 'No Entries are created.'
            }
            <NotificationContainer/>
        </div>
    );
}

export default LandDocumentsList;