import {Document, Page, View, Text, Font, StyleSheet, Image } from '@react-pdf/renderer';
import src from './NotoSans-Regular.ttf';
import src1 from './CedarvilleCursive-Regular.ttf';
import { useEffect, useState } from 'react';
import moment from 'moment';
Font.register({
    family: 'Noto Sans Hindi',
    fonts: [
      {
        src : src
      }
    ],
  })
  
  Font.register({
    family : 'Cedarville Cursive',
    fonts : [
      {
        src : src1
      }
    ],
    fontStyle : 'normal',
    fontWeight : 500
  })

const styles = StyleSheet.create({
kalavidhi : {
    margin : '10px',
    padding: '20px',
    backgroundColor : 'rgb(221, 221, 221)'
},
view : { margin : '10px' },
document : { margin: 10, fontFamily : 'Noto Sans Hindi' },
page : { fontSize : '12px', padding : '15px'},
pageEng : { fontSize : '10px', padding : '15px'},
mainHeading : { fontSize : '19px', textDecoration : 'underline', textAlign : 'center'},
textHeading : { marginTop : '10px', fontSize : '14px'},
textHeadingEng : { marginTop : '10px', fontSize : '12px' },
signature : {fontFamily : 'Cedarville Cursive'}
});

const PDFContent = (props) => {
 if(props.leasedAgreement.khasraNumbers === undefined){
    return <p>Loading...</p>
 }
  return (
    <Document style={styles.document}>
        {/* Hindi */}
        <Page size="A4" style={styles.page}>
            <View style={styles.view}>
                <Text style={styles.mainHeading}>
                Lease Agreement
                </Text>
                <Text style={{marginTop : '20px'}}>
                यह प्रमाणित किया जाता है कि मैं, {props.leasedAgreement.ownerName} पुत्र/पुत्री {props.leasedAgreement.ownerGuardian}, 
                गाँव {props.leasedAgreement.ownerVillage}, ब्लॉक {props.leasedAgreement.ownerBlock}, 
                जिला {props.leasedAgreement.ownerDistrict}, को अनुमति दे रहा/रही हूँ कि निवासी {props.leasedAgreement.farmerName}, 
                पुत्र/पुत्री/पत्नी {props.leasedAgreement.farmerGuardian}, गाँव {props.leasedAgreement.farmerVillage} ,ब्लॉक {props.leasedAgreement.farmerBlock}, 
                जिला {props.leasedAgreement.farmerDistrict}, को वर्ष {props.leasedAgreement.fromYear} में निम्नलिखित उन्नत कृषि पद्धतियों के
                 लिए पट्टा/खसरा संख्या {props.leasedAgreement.khasraNumbers?.join(', ')}  के क्षेत्रफल (एकड़ में) {props.leasedAgreement.area}  वाली मेरी भूमि पर 
                 कृषि कार्य करने की अनुमति प्रदान करता/करती हूं। यह समझौता इस बात की पुष्टि करता है कि इस नामांकित भूमि से उत्पन्न सभी कार्बन क्रेडिट भारतरोहण 
                 के नाम से पंजीकृत किए जाएंगे ताकि उन्हें किसी तीसरे पक्ष के खरीदार को बेचा जा सके। कार्बन क्रेडिट के निर्माण के बाद से 
                 ही उन पर सभी अधिकार, शीर्षक और हित भारतरोहण के पास निहित हो जाएंगे।
                </Text>

                <Text>भूस्वामी के हस्ताक्षर</Text>
                {props.leasedAgreement.ownerSign!="" ? <Image src={props.leasedAgreement.ownerSign}  style={{height : 50, width : 50, textAlign : 'right'}} /> : <Text style={styles.signature}>{props.leasedAgreement.ownerName}</Text>}
                <Text>दिनांक</Text>
                <Text>{props.leasedAgreement.ownerDate}</Text>
                <Text>स्वीकृतकर्ता,</Text>
                <Text>खेती करने वाले किसान के हस्ताक्षर</Text>
                {props.leasedAgreement.farmerSign!="" ? <Image src={props.leasedAgreement.farmerSign}  style={{height : 50, width : 50, textAlign : 'right'}} /> : <Text style={styles.signature}>{props.leasedAgreement.farmerName}</Text>}
                <Text>दिनांक</Text>
                <Text>{props.leasedAgreement.farmerDate}</Text>
            </View>
        </Page>

        {/* English */}


        <Page size="A4" style={styles.page}>
            <View style={styles.view}>
            <Text style={styles.mainHeading}>Lease Agreement</Text>
          
                     
                <Text style={{marginTop : '20px'}}>
                This is to certify that I, {props.leasedAgreement.ownerName} son/daughter/wife of {props.leasedAgreement.ownerGuardian}, 
                village {props.leasedAgreement.ownerVillage}, block {props.leasedAgreement.ownerBlock}, 
                district {props.leasedAgreement.ownerDistrict}, is allowing {props.leasedAgreement.farmerName}, 
                son/daughter/wife of {props.leasedAgreement.farmerGuardian}, village {props.leasedAgreement.farmerVillage} ,block {props.leasedAgreement.farmerBlock}, 
                district {props.leasedAgreement.farmerDistrict}, in the year {props.leasedAgreement.fromYear} to do cultivation by lease at my land with Patta/ Khasra No.
                 {props.leasedAgreement.khasraNumbers.join(', ')}  with an area  of (in acre) {props.leasedAgreement.area}. 
                 With this agreement, it has been agreed that all carbon credits generated from this enrolled land shall be registered in the name of BharatRohan to sell to the 
                 third-party buyer. BharatRohan shall have all the rights, title and interest to such carbon credits upon generation.
                </Text>
                 
                <Text>Signature of Landowner</Text>
                {props.leasedAgreement.ownerSign!="" ? <Image src={props.leasedAgreement.ownerSign}  style={{height : 50, width : 50, textAlign : 'right'}} /> : <Text style={styles.signature}>{props.leasedAgreement.ownerName}</Text>}
                <Text>Date:</Text>
                <Text>{moment(props.leasedAgreement.ownerDate).format('DD-MM-YYYY')}</Text>
                <Text>Accepted by,</Text>
                <Text>Signature of Cultivating Farmer</Text>
                {props.leasedAgreement.farmerSign!="" ? <Image src={props.leasedAgreement.farmerSign}  style={{height : 50, width : 50, textAlign : 'right'}} /> : <Text style={styles.signature}>{props.leasedAgreement.farmerName}</Text>}
                <Text>Date:</Text>
                <Text>{moment(props.leasedAgreement.farmerDate).format('DD-MM-YYYY')}</Text>
            </View>
        </Page>
        
    </Document>
  )
}

export default PDFContent;