import {Document, Page, View, Text, Font, StyleSheet, Image } from '@react-pdf/renderer';
import src from './NotoSans-Regular.ttf';
import src1 from './CedarvilleCursive-Regular.ttf';
import { useEffect, useState } from 'react';
Font.register({
    family: 'Noto Sans Hindi',
    fonts: [
      {
        src : src
      }
    ],
  })
  
  Font.register({
    family : 'Cedarville Cursive',
    fonts : [
      {
        src : src1
      }
    ],
    fontStyle : 'normal',
    fontWeight : 500
  })

const styles = StyleSheet.create({
kalavidhi : {
    margin : '10px',
    padding: '20px',
    backgroundColor : 'rgb(221, 221, 221)'
},
view : { margin : '10px' },
document : { margin: 10, fontFamily : 'Noto Sans Hindi' },
page : { fontSize : '12px', padding : '15px'},
pageEng : { fontSize : '10px', padding : '15px'},
mainHeading : { fontSize : '19px', textDecoration : 'underline', textAlign : 'center'},
textHeading : { marginTop : '10px', fontSize : '14px'},
textHeadingEng : { marginTop : '10px', fontSize : '12px' },
signature : {fontFamily : 'Cedarville Cursive'}
});

const PDFContentU = (props) => {
    console.log(props.undertaking);
 if(props.undertaking === undefined){
    return <p>Loading...</p>
 }
 return (
   
        <Document style={styles.document}>
        {/* Hindi */}
        <Page size="A4" style={styles.page}>
            <View style={styles.view}>
                 <Text style={styles.mainHeading}>
                Undertaking Agreement
                </Text>
               <Text style={{marginTop : '20px'}}>
                यह प्रमाणित किया जाता है कि मैं, {props.undertaking.ownerName},  
                पुत्र/पुत्री/पत्नी {props.undertaking.ownerGuardian},गाँव {props.undertaking.ownerVillage}, 
                ब्लॉक {props.undertaking.ownerBlock}, जिला {props.undertaking.ownerDistrict},अनुमति देता/देती हूं कि {props.undertaking.farmerName}, 
                वर्ष {props.undertaking.year} में मेरे खेत पर, जिसकी पट्टा/खसरा संख्या {props.undertaking.khasraNumbers.join(', ')} है और क्षेत्रफल (एकड़ में) {props.undertaking.area}  है, 
                निम्नलिखित उन्नत कृषि पद्धतियों को अपनाते हुए खेती कर सकता/सकती है। वह मेरा/मेरी {props.undertaking.relation} है। 
                इस समझौते के साथ, यह सहमति हुई है कि इस नामांकित भूमि से उत्पन्न होने वाले सभी कार्बन क्रेडिट भारतरोहण के नाम पर पंजीकृत किए जाएंगे 
                ताकि उन्हें तीसरे पक्ष के खरीदार को बेचा जा सके। कार्बन क्रेडिट के निर्माण के बाद से ही उन पर सभी अधिकार, शीर्षक और हित भारतरोहण 
                के पास निहित हो जाएंगे।
                </Text>

                <Text>भूस्वामी के हस्ताक्षर</Text>
                {props.undertaking.ownerSign!="" ? <Image src={props.undertaking.ownerSign}  style={{height : 50, width : 50, textAlign : 'right'}} /> : <Text style={styles.signature}>{props.undertaking.ownerName}</Text>}
                <Text>दिनांक</Text>
                <Text>{props.undertaking.ownerDate}</Text>
                <Text>स्वीकृतकर्ता,</Text>
                <Text>खेती करने वाले किसान के हस्ताक्षर</Text>
                {props.undertaking.farmerSign!="" ? <Image src={props.undertaking.farmerSign}  style={{height : 50, width : 50, textAlign : 'right'}} /> : <Text style={styles.signature}>{props.undertaking.farmerName}</Text>}
                <Text>दिनांक</Text>
                <Text>{props.undertaking.farmerDate}</Text>
            </View>
        </Page>

        {/* English */}


        <Page size="A4" style={styles.page}>
            <View style={styles.view}>
             <Text style={styles.mainHeading}>Undertaking Agreement</Text>
          
                     
               <Text style={{marginTop : '20px'}}>
                This is to certify that I, {props.undertaking.ownerName}, 
                son/daughter/wife of {props.undertaking.ownerGuardian}, 
                Village {props.undertaking.ownerVillage}, Block {props.undertaking.ownerBlock}, District {props.undertaking.ownerDistrict}, 
                is allowing {props.undertaking.farmerName}, to do cultivation at my land with Patta/ Khasra No. {props.undertaking.khasraNumbers.join(', ')}  
                with an area (in acre)  of {props.undertaking.area} for following Improved agricultural practices 
                in the year {props.undertaking.year} to . He/she is my {props.undertaking.relation}. 
                With this agreement, it has been agreed that all carbon credits generated from this enrolled land shall be 
                registered in the name of BharatRohan to sell to the third-party buyer. BharatRohan shall have all the rights, 
                title and interest to such carbon credits upon generation.
                </Text>
                 
                <Text>Signature of Landowner</Text>
                {props.undertaking.ownerSign!="" ? <Image src={props.undertaking.ownerSign}  style={{height : 50, width : 50, textAlign : 'right'}} /> : <Text style={styles.signature}>{props.undertaking.ownerName}</Text>}
                <Text>Date:</Text>
                <Text>{props.undertaking.ownerDate}</Text>
                <Text>Accepted by,</Text>
                <Text>Signature of Cultivating Farmer</Text>
                {props.undertaking.farmerSign!="" ? <Image src={props.undertaking.farmerSign}  style={{height : 50, width : 50, textAlign : 'right'}} /> : <Text style={styles.signature}>{props.undertaking.farmerName}</Text>}
                <Text>Date:</Text>
                <Text>{props.undertaking.farmerDate}</Text>
            </View>
        </Page>
        
    </Document>


  )
}

export default PDFContentU;
