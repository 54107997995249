
import { useEffect, useState } from "react";
import LandRecordForm from "./LandRecordForm/LandRecordForm";
import LandDocumentsList from "./LandRecordForm/LandDocumentsList";
import { getfarmerById, getLandRecords, getLocation } from "../../../APIs/apiCalls";
import Layout from "../../../Layout";
import Undertaking from "./LandRecordForm/Undertaking";
import AgreementLists from "./LandRecordForm/AgreementLists";
import LeaseAgreementSign from "./LandRecordForm/LeaseAgreementSign";
import FarmerSearchBar from "../../../Components/FarmerSearchBar/FarmerSearchBar";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import UndertakingSign from "./LandRecordForm/UndertakingSign";

const AddLandRecords = () => {
    const [data, setData] = useState(undefined);
    const [farmer, setFarmer] = useState(undefined);
    const [records, setRecords] = useState([]);
    const [khasraNumbers, setKhasraNumbers] = useState([]);
    const [successKhasraNumbers, setSuccessKhasraNumbers] = useState(0);
    const [undertaking, setUndertaking] = useState(null);
    const [leasedAgreement, setLeasedAgreement] = useState(null);
    const [location, setLocation] = useState([]);
    const [addLeasedButtonClicked, setAddLeasedButtonClicked] = useState(false);
    const [addUndertakingButtonClicked, setAddUndertakingButtonClicked] = useState(false);
    const [finishStatus, setFinishStatus] = useState(false);
    const navigate = useNavigate();

    const alertUser = (event) => {
        event.preventDefault();
        event.returnValue = "Are you sure you want to leave? Changes you made may not be saved."; // Custom message may not work in all browsers
    };

    const onBackButtonEvent = (event) => {
        event.preventDefault();
        if (!finishStatus) {
            if (window.confirm("Do you want to go back to the dashboard?")) {
                setFinishStatus(true); // Confirm navigation
                navigate("/dashboard");
            } else {
                window.history.pushState(null, null, window.location.hash);
                setFinishStatus(false); // Stay on the page
            }
        }
    };

     

    useEffect(() => {
        window.history.pushState(null, null, window.location.hash);
        window.addEventListener('popstate', onBackButtonEvent);
        window.addEventListener('beforeunload', alertUser);

        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
            window.removeEventListener('beforeunload', alertUser);
        };
    }, [finishStatus]);




    //sends land records
    const recordsChangeHandler = (values, farmerData) =>{
        // console.log(values);
        setRecords(values);
        khasraValuesSet(values, farmerData);
    }

    //update agreements on add/update/delete
    const updateAgreements = async() => {
        const response = (await getLandRecords(data._id));
        console.log(response);
        setAddLeasedButtonClicked(false)
        setAddUndertakingButtonClicked(false)
        setLeasedAgreement(response.data.leasedAgreement);
        setUndertaking(response.data.undertakingDocument);
    }

    //this function changes the list of khasra numbers generated.
    const khasraValuesSet = (values, farmerData) => {
        let arr = [];
        let new_set = new Set();
        for(let item of values){
            for(let number of item.khasraNumbers){
                new_set.add(number.trim());
            }
        }
        
        setSuccessKhasraNumbers(Array.from(new_set));

        //eliminate values which is present in
        for(let item of farmerData.ownedLandKhasraNumbers){
            item = item.trim();
            if(Array.from(new_set).includes(item) === false){
                arr.push({value : item, label : item});
            }
        }
        for(let item of farmerData.leasedLandKhasraNumbers){
            item = item.trim();
            if(Array.from(new_set).includes(item) === false){
                arr.push({value : item, label : item});
            }
        }
        // console.log(arr);
        
        setKhasraNumbers(arr);
    }

    const handleFilterValues = async (farmer) => {


        farmer = (await getfarmerById(farmer._id)).data;
        
        
        const {data} = (await getLandRecords(farmer._id));
       

        setLeasedAgreement(data.leasedAgreement);
        setUndertaking(data.undertakingDocument);
        setRecords(data.lands);
        
        setFarmer(farmer)
        khasraValuesSet(data.lands, data);
        setData(data);
       
    }

    useEffect(() => {
        (async () => {
            try{
                const response = await getLocation();
                setLocation(response.data);
            }catch(e){
                NotificationManager.error('Internal Server error');
            }
        })();
    },[])

    return (
      
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="row" style={{paddingTop : '80px'}}>
                            <div className="col-12 grid-margin">
                                <div className="card">
                                    <div className="card-body">
                                        <FarmerSearchBar change={(farmer) => handleFilterValues(farmer)}/>
                                    </div>
                                </div>
                                {
                                    data!=undefined ? 
                                    <div className="card mt-3">
                                        <div className="card-body">
                                            <LandRecordForm data={data} khasraNumbers={khasraNumbers} changeRecords={recordsChangeHandler}/>
                                        </div>
                                    </div> : ''
                                }
                                
                                {
                                    data!=undefined ? 
                                    <div className="card mt-3">
                                        <div className="card-body">
                                            <LandDocumentsList data={data} records={records} changeRecords={recordsChangeHandler} success={successKhasraNumbers} pending={khasraNumbers}/>
                                        </div>
                                    </div> : ''
                                }                  
                                { 
                                    data!= undefined  && <div className="card mt-3">
                                        <div className="card-body">
                                            <AgreementLists undertaking={undertaking} leasedAgreement={leasedAgreement} changeRecords={updateAgreements} addL={() => setAddLeasedButtonClicked(true)} addU={() => setAddUndertakingButtonClicked(true)}/>
                                        </div>
                                    </div> 
                                }

                                {
                                    addUndertakingButtonClicked === true ? 
                                    <div className="card mt-3">
                                        <div className="card-body">
                                            {/* <Undertaking  farmerId={data._id} changeRecords={updateAgreements}/> */}
                                            <UndertakingSign farmer={farmer} location={location} changeRecords={updateAgreements} remove={() => setAddUndertakingButtonClicked(false)}  />
                                        </div>
                                    </div> : ''
                                }

                                {
                                    addLeasedButtonClicked === true ? 
                                    <div className="card mt-3">
                                        <div className="card-body">
                    
                                             {/* <LeaseAgreement farmerId={data._id} changeRecords={updateAgreements}/>  */}
                                            <LeaseAgreementSign farmer={farmer} location={location} changeRecords={updateAgreements} remove={() => setAddLeasedButtonClicked(false)}/>
                                        </div>
                                    </div> : ''
                                }
                            </div>
                        </div>
                    </div>
                </div>    
    );
}

export default AddLandRecords;