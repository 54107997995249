import {Document, Page, View, Text, Font, StyleSheet, Image } from '@react-pdf/renderer';
import src from './NotoSans-Regular.ttf';
import src1 from './CedarvilleCursive-Regular.ttf';
import { useEffect, useState } from 'react';
Font.register({
  family: 'Noto Sans Hindi',
  fonts: [
    {
      src : src
    }
  ],
})

Font.register({
  family : 'Cedarville Cursive',
  fonts : [
    {
      src : src1
    }
  ],
  fontStyle : 'normal',
  fontWeight : 500
})

const styles = StyleSheet.create({
  kalavidhi : {
    margin : '10px',
    padding: '20px',
    backgroundColor : 'rgb(221, 221, 221)'
  },
  view : { margin : '10px' },
  document : { margin: 10, fontFamily : 'Noto Sans Hindi' },
  page : { fontSize : '12px', padding : '15px'},
  pageEng : { fontSize : '10px', padding : '15px'},
  mainHeading : { fontSize : '19px', textDecoration : 'underline', textAlign : 'center'},
  textHeading : { marginTop : '10px', fontSize : '14px'},
  textHeadingEng : { marginTop : '10px', fontSize : '12px' },
  signature : {fontFamily : 'Cedarville Cursive'}
});
const PDFContent = (props) => (

  <Document style={styles.document}>
    {/* Hindi Format */}
    <Page size="A4" style={styles.page}>
      <View style={styles.view}>
        <Text style={styles.mainHeading}>कार्बन क्रेडिट उत्पादन और अधिकार संचार समझौता</Text>
        <Text style={{marginTop : '20px'}}>
            'कोशर कार्बन जनरेशन प्रोग्राम' में भाग लेने के लिए कोशर क्लाइमेट इंडिया प्राइवेट लिमिटेड ("कोशर"), भारतरोहण एयरबोर्न
            इनोवेशंस प्राइवेट लिमिटेड ("भारतरोहण") और व्यक्ति या संगठन ("आप", "किसान" या "उगाने वाला") के बीच 'कोशर कार्बन
            जनरेशन एंड ट् ांसफर ऑफ़ राइट्स अग्रीमेंट' ('समझौता') को सम्पन्न किया जाता है ।
        </Text>
        <Text style={{marginTop : '10px'}}>
          जबकि किसान कोशर के द्वारा निर्धारित पात्रता मानदंडों के अधीन अपनी स्वयं की / किराये की गई कृषि भूमि को ' कोशर कार्बन 
          जनरेशन प्रोग्राम ' के तहत नामांकित कराने में रूचि रखता है । इस समझौते को सभी तीन पक्षों ( " प्रभावी तिथि " ) द्वारा समझौते पर 
          हस्ताक्षर करने और स्वीकृति देने पर प्रभावी होगा । कोशर और भारतरोहण के बीच 13 फरवरी 2023 को हस्ताक्षरित मेमोरेंडम भी 
          इस समझौते का हिस्सा माना जाएगा । यह समझौता केवल उन सभी पक्षों द्वारा हस्ताक्षर किए गए एक लिखित दस्तावेज द्वारा 
          संशोधित या संशोधित किया जा सकता है ।
        </Text>
        <Text style={styles.textHeading}>व्यक्ति या एकाधिकार ( किसान या उत्पादक ): </Text>
       
         
          <Text>नाम : {props.farmerDetails.firstname} {props.farmerDetails.lastname}  </Text> 
          <Text>आधार कार्ड संख्या : {props.farmerDetails.aadharNumber} </Text> 
          <Text>पता : {props.farmerDetails.tehsil} </Text> 
          <Text>गाँव :  {props.farmerDetails.village} </Text>
          <Text>कार्बन क्रेडिट प्रोजेक्ट डेवलपर : कोशर क्लाइमेट इंडिया प्रा . लि . ( " कोशेर " ) </Text>
          <Text>पंचायत :  {props.farmerDetails.gramPanchayat} </Text> 
          <Text>पिता का नाम :  {props.farmerDetails.guardian} </Text>
          <Text>फोन नंबर : {props.farmerDetails.mobileNumber} </Text> 
          <Text>ब्लॉक : {props.farmerDetails.block}  </Text> 
          <Text>कार्यान्वयन साथी या ग्राउंड एक्सटेंशन साथी : भारतरोहण एयरबोर्न इनोवेशंस प्राइवेट लिमिटेड ( " भारतरोहण " )  </Text>
        
      <Text style={styles.textHeading}>कवर्ड एकड़ : </Text>
        <Text>कुल जमीन ( एकड़ ) जो किसान के सीधे नियंत्रण में है और कार्बन परियोजना कार्यक्रम के तहत पंजीकृत होने के योग्य है : {props.farmerDetails.ownedLandArea + props.farmerDetails.leasedLandArea}</Text> 
        <Text>स्वामित्व में ( एकड़ ) : {props.farmerDetails.ownedLandArea} </Text>
        <Text>खसरा संख्या ( एँ ) : {props.farmerDetails.ownedLandKhasraNumbers.join(', ')} </Text> 
        <Text>पट्टे में भूमि ( एकड़ ) : {props.farmerDetails.leasedLandArea} </Text>
        <Text>खसरा संख्या ( एँ ) :  {props.farmerDetails.leasedLandKhasraNumbers.join(', ')} </Text>
      
      </View>
      <View style={styles.kalavidhi}>
        <Text style={styles.textHeading}>कालावधि</Text> 
        <Text>7 वर्ष , परियोजना की सफलता पर आधारित नवीनीकरण । </Text>
        <Text>दस्तावेज़ की सुरक्षा</Text> 
        <Text>इस अनुबंध की शर्तें भूमि के नियंत्रण में परिवर्तनों को भी जीतेंद्रिय करेंगी और अनुबंध की अवधि के लिए भविष्य में भूमि के नियंत्रण में रहने वाले व्यक्तियों के लिए लागू होगी । </Text>
        <Text>उन्नत कृषि विधियाँ </Text>
       
        <Text>1.  जल प्रबंधन - □□□ में लगातार भराई से अल्टरनेट वेटिंग एंड ड्राईइंग ( एडब्ल्यूडी ) में बदलाव । </Text>
        <Text>2.  नाइट्रोजन प्रबंधन - नाइट्रोजन उपयोग का अनुकूलन ।</Text>
        <Text>3.  जीरो टिलेज ( बिना □□□□□ ) - पारंपरिक या कम □□□□□ से शून्य टिलेज में बदलाव । </Text>
        <Text>4.  उन्नत जुताई- पारंपरिक से कम □□□□□ में बदलाव |</Text>
        <Text>5.  कवर क्रॉपिंग - कवर क्रॉपिंग से कवर क्रॉपिंग में बदलाव । </Text>
        </View>
        <View style={styles.view}>
        <Text style={styles.textHeading}>प्रारंभ दिनांक </Text>
        <Text>परियोजना के तहत खेती अभ्यास की शुरुआत की तिथि जून 2023_ है । नीचे हस्ताक्षर करके , आप इस समझौते से 
        बंधने के लिए सहमत होते हैं , भारतरोहण और कोशर की मदद से कार्बन उत्पादन कार्यक्रम में भाग लेने के लिए सहमत होते हैं ; 
        और सहमत होते हैं कि आपकी नामांकित भूमि से उत्पन्न सभी कार्बन क्रेडिट को कोशर क्लाइमेट और भारतरोहण के नाम से 
        पंजीकृत किया जाएगा , और कार्बन क्रेडिट उत्पन्न होते ही कोशर क्लाइमेट और भारतरोहण के पास सभी अधिकार , शीर्षक और 
        हित होंगे । </Text>

        <Text style={styles.textHeading}>परियोजना भूमि :</Text>
          <Text>आपको अपनी भूमि के विशिष्ट खेतों को भारतरोहण और कोशर के साथ नामांकित करने के लिए जिम्मेदार 
          होना होगा । नामांकन भारतरोहण द्वारा उसकी विवेकाधीनता से स्थापित की जाने वाली पात्रता आवश्यकताओं के अधीन होगा । 
          इस समझौते का अधिकार आपकी सभी पात्र और नामांकित भूमि पर लागू होता है , जिसमें भविष्य के नामांकन भी शामिल हैं । </Text>
        
      
        <Text style={styles.textHeading}>कार्बन क्रेडिट : </Text>
        <Text>कार्बन क्रेडिट 1 मीट्रिक टन सीओ 2 समतुल्य ( सीओ 2 ई ) से सम्बंधित होते हैं , चाहे वे मिट्टी में संचयित हों या आपकी उन्नत कृषि व्यवस्थाओं ( जैसा कि ऊपर दिए गए हैं ) या अन्य ग्रीनहाउस गैस उत्सर्जनों ( “ जीएचजी " ) से संबंधित न हों जो न की वातावरण में उत्सर्जित किए गए हों , कोशर कार्बन जनरेशन प्रोग्राम के नियमों का पालन करने पर ।  </Text>
        
        <Text  style={styles.textHeading}>कार्बन मानक : </Text>
        <Text>गोल्ड स्टँडर्ड या वेरा सत्यापित कार्बन मानक ( VCS ) या समान प्रतिष्ठित कार्बन मानक ।</Text>

        <Text style={styles.textHeading}>मैथोडोलॉजी : </Text>
        <Text> आपकी नामांकित जमीन से कार्बन क्रेडिट उत्पन्न करने की पद्धति एक मैथोडोलॉजी के अधीन होगी जो कोशेर के 
        द्वारा एकमात्र विवेक से चुनी जाती है , जो कार्बन क्रेडिट योग्यता , मात्रा और सत्यापन आवश्यकताओं को स्पष्ट करती है और किसी 
        भी मान्यता प्राप्त कार्बन मानक के तहत सूचीबद्ध होती है ।</Text>

        <Text style={styles.textHeading}>मूल्य निर्धारण और भुगतान : </Text>
        <Text> जब कार्बन क्रेडिट प्रोग्राम से उत्पन्न कार्बन क्रेडिट थर्ड - पार्टी खरीदार को बेचे जाएं और कोशर 
          द्वारा भुगतान किया जाए , उस समय भारतरोहण को कार्बन मूल्य के एक अहम हिस्से की मिलेगी । भारतरोहण इस कार्बन फंड का 
          उपयोग उन्नत किए गए किसानों के लाभार्थ करने के लिए करेगा , जो कि एग्री - परामर्श , मूल्य छूट , नकद भुगतान या इससे मिलती 
          जुलती चीजों जैसे अतिरिक्त सेवाओं के रूप में हो सकती हैं , जैसे कि भारतरोहण और भागीदार किसान ( ओं ) के बीच सहमति 
          होगी । </Text>
        
        <Text style={{marginTop : '10px'}}>   
          आप सहमत होते हैं कि कोशर आपकी भूमि पर कार्बन क्रेडिट या कार्बन गुणवत्ताओं की कोई भी उत्पादन करने वाली एकमात्र 
           संस्था होगी । यदि आप अपनी कोई भी भूमि का डबल - नामांकन करते हैं , तो कोशर अपने विवेकाधीन में , ( a ) कारण के लिए इस 
          समझौते को समाप्त कर सकता है या ( b ) आपकी भागीदारी को डबल - नामांकन भूमि के संबंध में समाप्त कर सकता है । इन दोनों 
          मामलों में , कोशेर आपके प्रति और किसी भी बकाया और / या अविवेकाधीन भुगतान के नुकसान के बिना आपके लिए जिम्मेदार 
          नहीं होगी । 
        </Text>
                                    
        
        <Text style={styles.textHeading}>डेटा : </Text>
        <Text>आपको अपने नामांकित जमीन पर सुधारित कृषि विधियों के रिकॉर्ड और अन्य डेटा , दस्तावेज और जानकारी कोशर के लिए प्रदान करने की आवश्यकता है जो कोशर कार्बन जनरेशन प्रोग्राम के तहत आपकी पात्रता को सत्यापित करने के लिए 
        आवश्यक हो सकती है । आप कोशर , उसके प्रतिनिधि और तृतीय - पक्ष सत्यापकों को समय - समय पर डेटा इकट्ठा करने के लिए 
        आपकी जमीन में प्रवेश और पहुँच की अधिकार देते हैं , जो विधि में उल्लिखित तरीकों में मृदा नमूने और अन्य ऐसी प्रोटोकॉल के 
        माध्यम से हो सकता है । कृपया ध्यान दें , इस समझौते के तहत साझा किए गए सभी डेटा आपके नाम हैं और नामांकित जमीन के 
        सभी स्वामित्व अधिकार आपके पास रहेंगे । </Text>

        <Text style={{marginTop : '10px'}}>समझौता प्रभावी तिथि के रूप में लागू किया जाता है और उस दिन से अंजाम दिया जाता है । </Text>
        <Text style={{marginTop : '10px', color : 'red'}}>मैं इस समझौते में उल्लिखित शर्तों को समझता हूं और मैं इन सभी शर्तों से पूरी तरह सहमत हूं । मुझे इस 
            समझौते को हस्ताक्षर करते समय कोई बाध्य करने वाला नहीं था और न मेरे साथ कोई भ्रम दिया गया था 
         और मैंने यह समझौता यहां उल्लिखित शर्तों के अर्थ को पूरी तरह समझकर हस्ताक्षर किया है ।</Text>
        <Text>दिनांक : {(new Date()).getDate()}/{(new Date()).getMonth()+1}/{(new Date()).getFullYear()} </Text>
        <Text>हस्ताक्षर :</Text>
         {props.signatureURI!="" ? <Image src={props.signatureURI}  style={{height : 50, width : 50}} /> : <Text  style={styles.signature}>{props.farmerDetails.firstname} {props.farmerDetails.lastname} </Text>}
        
        </View>
    </Page>
    
    {/* English Format */}
    
    <Page size="A4" style={styles.pageEng}>
      <View style={styles.view}>
        <Text style={styles.mainHeading}>Carbon Credit Generation and Transfer of Rights Agreement</Text>
        <Text style={{marginTop : '20px'}}>
        The Kosher Carbon Generation and Transfer of Rights Agreement (“Agreement”) is entered into between Kosher Climate India Pvt. Ltd. (“Kosher”), Bharatrohan Airborne Innovations Pvt. Ltd. (“Bharatrohan”) and the individual or entity (“You”, “Farmer”, or “Grower”) working with Kosher and Bharatrohan to participate in Kosher Carbon Generation Program.
        </Text>
        <Text style={{marginTop : '10px'}}>
          Whereas Grower is interested to enroll his/her owned/leased agricultural land(s) under the Kosher Carbon Generation Program, subject to eligibility criteria requirements set out by Kosher at their discretion. This agreement is effective as of when this is signed and accepted by all the three parties (“Effective Date”). The MoU between Kosher and Bharatrohan signed on February 13, 2023, shall be deemed to form part of this Agreement. This Agreement constitutes the entire agreement between the Parties and supersedes all prior agreements relating to the Carbon Credit Generation Agreement. This Agreement may be amended or modified only by a written instrument signed by all the Parties.
        </Text>
        <Text style={styles.textHeadingEng}>Individual or Entity (Farmer or Grower): </Text>
       
         
          <Text>Name : {props.farmerDetails.firstname} {props.farmerDetails.lastname}  </Text> 
          <Text>Aadhar Card Number : {props.farmerDetails.aadharNumber} </Text> 
          <Text>Address : {props.farmerDetails.tehsil} </Text> 
          <Text>Village :  {props.farmerDetails.village} </Text>
          <Text>Carbon credit Project Developer:  Kosher Climate India Pvt. Ltd. (“Kosher”)  </Text>
          <Text>Panchayat :  {props.farmerDetails.gramPanchayat} </Text> 
          <Text>Father's Name :  {props.farmerDetails.guardian} </Text>
          <Text>Phone Number : {props.farmerDetails.mobileNumber} </Text> 
          <Text>Block : {props.farmerDetails.block}  </Text> 
          <Text>Implementation partner or Ground Extension partner:  Bharatrohan Airborne Innovations Pvt. Ltd.
                                    (“Bharatrohan”) </Text>
        
      <Text style={styles.textHeadingEng}>Covered Acres:  </Text>
        <Text>Total Land (acres) which is under direct control of farmer and eligible to be enrolled under the carbon project
                                    program: {props.farmerDetails.ownedLandArea + props.farmerDetails.leasedLandArea}</Text> 
        <Text>Owned (acre) : {props.farmerDetails.ownedLandArea} </Text>
        <Text>Khasra Number(s) : {props.farmerDetails.ownedLandKhasraNumbers.join(', ')} </Text> 
        <Text>Land under lease (acre) : {props.farmerDetails.leasedLandArea} </Text>
        <Text>Khasra Number(s) :  {props.farmerDetails.leasedLandKhasraNumbers.join(', ')} </Text>
      
      </View>
      <View style={styles.kalavidhi}>
        <Text style={styles.textHeadingEng}>Term</Text> 
        <Text>7 years, renewable based on the success of the project.</Text>
        <Text>Survival of the Document.</Text>
        <Text>The terms and conditions of this contract will survive changes to control of the land(s) and will be applicable to those
                                    who control the land(s) in future for the duration of the contract term. </Text>
        <Text>Improved Agricultural Practices </Text>
       
        <Text>1.  Water Management  -  A shift from continues flooding to Alternate Wetting and Drying (AWD) in Paddy. </Text>
        <Text>2.  Nitrogen Management -  Optimization of nitrogen use.</Text>
        <Text>3.  Zero Tillage (No-Till) – A shift from conventional or reduced tillage to zero tillage. </Text>
        <Text>4.  Improved Tillage – A shift from conventional to reduced tillage.</Text>
        <Text>5.  Cover Cropping – A shift from no cover cropping to cover cropping. </Text>
        </View>
        <View style={styles.view}>
        <Text style={styles.textHeadingEng}>Start Date :</Text>
        <Text>The start date of the cultivation practice under the project is _____June 2023_.By signing below, you agree to be
                                    bound by this Agreement, to participate in the Carbon Generation Program with the help of Bharatrohan and
                                    Kosher; and agree that all carbon credits generated from your enrolled land shall be in registered in the name of
                                    Kosher Climate and Bharatrohan, and Kosher climate and Bharatrohan shall have all the rights, title and interest to
                                    such carbon credits upon generation.</Text>

        <Text style={styles.textHeadingEng}>Project Land : </Text>
          <Text>You are responsible for enrolling specific fields of Your land with Bharatrohan and Kosher.
                                    Enrollment is subject to eligibility requirements as may be established by Bharatrohan in its discretion. This
                                    Agreement applies to all Your eligible and enrolled land, including future enrollments.</Text>
        
      
        <Text style={styles.textHeadingEng}>Carbon Credits :</Text>
        <Text> Carbon Credits equate to 1 metric ton of CO2 equivalent (CO2e) either sequestered in soil or not
                                    emitted to the atmosphere as a result of Your implementation of the Improved Agricultural Practices (as listed
                                    above) or other greenhouse gas emissions (“GHGs”) sequestered or avoided, subject to compliance with the rules
                                    of Kosher Carbon Generation Program.  </Text>
        
        <Text  style={styles.textHeadingEng}>Carbon Standard:</Text>
        <Text>Gold Standard or Verra Verified Carbon Standard (VCS) or similar recognized carbon standards.</Text>

        <Text style={styles.textHeadingEng}>Methodology : </Text>
        <Text>  he generation of Carbon Credits from your enrolled lands will be subject to a methodology listed
                                    under any recognized carbon standard and which sets forth Carbon Credit qualification, quantification and
                                    verification requirements (“Methodology”) and that is selected and applied by Kosher in its sole discretion.</Text>

        <Text style={styles.textHeadingEng}>Pricing and Payment : </Text>
        <Text> Bharatrohan shall get a significant percentage of carbon value as and when the carbon
                                    credits generated from the program are sold to a third-party buyer and payments are realized by Kosher.
                                    Bharatrohan shall utilize this carbon fund for the upliftment of enrolled farmers by multiple means including
                                    additional services like Agri-consultancy, price discounts, cash payouts or similar things, as to be agreed between
                                    Bharatrohan and the participating farmer(s).</Text>
        
                                    <Text style={styles.textHeadingEng}>Exclusivity : </Text>
                                    <Text>  You agree that Kosher will be the exclusive entity for the generation of any Carbon Credits or Carbon
                                    Attributes on Your Land. In the event You double-enroll any portion of Your land, Kosher may, in its discretion,
                                    terminate: (a) this Agreement for cause, or (b) Your participation with respect to any Land that is double-enrolled,
                                    in either case, without further liability of Kosher to You, including the forfeiture of any outstanding and/or
                                    unvested payments. </Text>
                                    
        
        <Text style={styles.textHeadingEng}>Data : </Text>
        <Text> You are required to provide Kosher with records of improved agricultural practices on your enrolled land as
                                    well as other data, documents and information as required or requested by Kosher to verify Your eligibility under
                                    the Kosher Carbon generation program. You grant Kosher, its representatives and third-party verifiers the right to
                                    enter and access Your Land from time to time to collect Data, including, without limitation, through soil sampling
                                    and other such protocols as mentioned in the methodology. Please note, all data shared under this agreement
                                    belongs to you and all ownership rights to enrolled land shall remain with you. </Text>

        <Text style={{marginTop : '10px'}}>Agreement is executed and delivered as of the Effective Date:</Text>
        
        
        <Text style={{marginTop : '10px', color : 'red'}}>
        I understand the terms stated in this agreement and I fully agree to all these terms. I have not been coerced or
                                                misrepresented when signing this agreement and I have signed this agreement with full knowledge of the meaning
                                                of the terms here.
        </Text>
        <View>
          <Text>Date : {(new Date()).getDate()}/{(new Date()).getMonth()+1}/{(new Date()).getFullYear()} </Text>
          <Text>
          Signature : </Text>
            {props.signatureURI!="" ? <Image src={props.signatureURI}  style={{height : 50, width : 50, textAlign : 'right'}} /> : <Text style={styles.signature}>{props.farmerDetails.firstname} {props.farmerDetails.lastname} </Text>}
        </View>
      </View>
    </Page>
   
  </Document>
);

export default PDFContent;