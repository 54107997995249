const validate = (values) => {
    const errors = {};
    // console.log(values);
    if(values.khasraNumbers.length===0){
        errors.khasraNumbers = 'Select Khasra Numbers from the dropdown';
    }
    if(!values.landDocument){
        errors.landDocument = "Land Document is required."
    }
    if(values.area === ''){
        errors.area = "Area is required"
    }
    else if(values.area <= 0){
        errors.area = "Area should be greater than zero."
    }
    return errors;
}

export default validate;