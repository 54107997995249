import { MaterialReactTable } from 'material-react-table';
import { useEffect, useState } from 'react';
import { deleteFarmer, exportCSV,  getTableData,  uploadFarmerByCSV } from '../../../../APIs/apiCalls';
import { useNavigate } from 'react-router-dom';
import AlertConfirm, { Button } from "react-alert-confirm";
import "react-alert-confirm/lib/style.css";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Spinners from '../../../../Components/Spinners/Spinners';
import CSVErrorMessages from '../../../../Components/CSVErrorMessages/CSVErrorMessages';
import { isFileCSV } from '../../../../Utils/commonFunctions';
import { fetchUrl } from '../../../../APIs/server';
const url_ = fetchUrl

AlertConfirm.config({
    maskClosable: false,
    closeAfter: () => {
      // console.log("close");
    }
});
const FarmerData = () => {
      const navigate = useNavigate();
      const [data, setData] = useState(undefined);
      const [flag, setFlag] = useState(false);
      const [disabled, setDisabled] = useState(false);
      const [fileUploadingErrors, setFileUploadingErrors] = useState([]);
      const userType = localStorage.getItem('type');
      const [disabledIds, setDisabledIds] = useState([]);
        const [pagination, setPagination] = useState({
            pageIndex: 0,
            pageSize: 10,
        });

    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);

    //table state
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');

    
      const columns =[
        {
            accessorKey : 'farmername',
            header: 'Farmer Name',
            Cell: ({ cell }) => {
                return <a style={{color : "blue", textDecoration : 'underline'}} target='_blank' href={`/#/farmer-profile/${cell.row.original._id}`} >{cell.row.original.farmername.toUpperCase()}</a>;
            }
        },
        {
            accessorKey : 'guardian',
            header: 'Guardian',
            Cell: ({ cell }) => {
                return <>{cell.row.original.guardian?.toUpperCase()}</>;
            }
        },
        {
            accessorKey: 'isAWDFarmer',
            header: 'AWD Farmer',
            Cell : ({cell}) => {
                return <>{cell.getValue() === true ? 'TRUE' : 'FALSE'}</>
            }
        },
        {
            accessorKey: 'projectName',
            header: 'Project Name',
            Cell : ({cell}) => {
                return <>{cell.getValue() === undefined ? 'Not Entered' : cell.getValue().toUpperCase()}</>
            }
        },
        {
            accessorKey: 'state',
            header: 'State',
            Cell : ({cell}) => {
                return <>{cell.getValue().toUpperCase()}</>
            }
        },
        {
            accessorKey: 'district',
            header: 'District',
            Cell : ({cell}) => {
                return <>{cell.getValue().toUpperCase()}</>
            }
        },
        
        {
            accessorKey: 'tehsil',
            header: 'Tehsil',
            Cell : ({cell}) => {
                return <>{cell.getValue().toUpperCase()}</>
            }
        },
        {
            accessorKey: 'block',
            header: 'Block',
            Cell : ({cell}) => {
                return <>{cell.getValue().toUpperCase()}</>
            }
        },
        {
            accessorKey: 'village',
            header: 'Village',
           
            Cell : ({cell}) => {
                return <>{cell.getValue().toUpperCase()}</>
            }
        },
        {
            accessorKey: 'mobileNumber',
            enableColumnFilter : false,
            header: 'Contact'
        },
        {
            accessorKey: 'ownedLandArea',
            header: 'Owned Land Area',
            enableColumnFilter : false,
            accessorFn: (data) => {
                return data.ownedLandArea + ' Acres';
            },
        },
        {
            accessorKey : 'ownedLandKhasraNumbers',
            header: 'Owned Land Khasra Numbers',
            enableColumnFilter : false,
            Cell: ({cell}) => {
                let value = cell.getValue();
                return <div>{ value.length > 0 && value!=undefined ? value.join(', ') : <span className='text-danger' style={{fontStyle : 'italic'}}>Not Entered</span>}</div>;
            },
        },
        {
            accessorKey: 'leasedLandArea', 
            header: 'Leased Land Area',
            enableColumnFilter : false,
            accessorFn: (data) => {
                return data.leasedLandArea + ' Acres';
            },
        },
        {
            accessorKey : 'leasedLandKhasraNumbers',
            enableColumnFilter : false,
            header: 'Leased Land Khasra Numbers',
            Cell: ({cell}) => {
                let value = cell.getValue();
                return <div>{ value.length > 0 && value!=undefined ? value.join(', ') : <span className='text-danger' style={{fontStyle : 'italic'}}>Not Entered</span>}</div>;
            },
        },
        {
            accessorKey: 'totalArea',
            enableColumnFilter : false,
            header: 'Total Area',
            accessorFn: (data) => {
                return <>{data.leasedLandArea + data.ownedLandArea} Acres </>;
            },
        }
      ];
      const handleSaveRowEdits = (row) => {
         navigate(`/update/${row.original._id}`)
      }
      const handleCancelRowEdits = (row) => {
        // console.log(row);
        AlertConfirm({
            title: 'Are you sure?',
            desc: 'This cannot be undone.',
            onOk: () => {
              setDisabledIds(prev => [...prev, row.original._id]);
              deleteFarmer(row.original._id).then((response)=>{
                // console.log(response);
                if(response.result === "success"){
                    NotificationManager.success("Farmer has been removed from the list");
                    let farmers = response.data;
                    for(let item of farmers) {
                        item['farmername'] = item.firstname.toUpperCase() + ' ' + item.lastname.toUpperCase();
                    }
                    
                    setData(farmers);
                }
              })
            },
            onCancel: () => {
              // console.log('cancel');
            }
        });
    };
    const csvUploadHandler = (event) => {
        setFileUploadingErrors([]);
        const files = event.target.files[0];
        if(files){
            if(isFileCSV(files) === false){
                return;
            }
            let formData = new FormData();
            formData.append('file', files);
            setDisabled(true);
            uploadFarmerByCSV(formData).then((response) => {
               
                setFlag(!flag);
                setDisabled(false);
                if(response.result ==='success'){
                    NotificationManager.success("CSV File uploaded successfully.");
                    setFileUploadingErrors(response.errors);
                }else{
                    NotificationManager.error("Internal server error");
                }
            }).catch((error) => {
                setDisabled(false);
                NotificationManager.error("Unable to Process CSV file. Please check the headers.");
            })
            
            event.target.value = "";
        }
    }
    const handleExportData = async () => {
        try {
            const export_url = new URL(`${url_}/export-farmer`);
            for(let item of columnFilters){
                export_url.searchParams.set(item.id, item.value)
            }
            const blob = await exportCSV(export_url.href);
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Farmer_Onboarded_Data.csv');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
        console.error('Error downloading CSV:', error);
        }
    };

    useEffect(()=>{
        
        (async()=>{

            const url = new URL(`${url_}/manage-farmers`);
            url.searchParams.set('page', pagination.pageIndex + 1);
            url.searchParams.set('limit', pagination.pageSize);

            for(let item of columnFilters){
                url.searchParams.set(item.id, item.value)
            }
            if(globalFilter!=''){
                url.searchParams.set('farmername', globalFilter);
            }
            setIsRefetching(true)
            let response = (await getTableData(url));
            let farmers = response.data;
            console.log(response);
            let count = response.recordCount;
            setRowCount(count)
            setIsRefetching(false)
            setData(farmers);
        })();
    },[
        columnFilters,
        globalFilter,
        pagination.pageIndex,
        pagination.pageSize
    ]);
    if(data === undefined){
        return <Spinners/>
    }
    return (
        <div className="main-panel">
            <div className="content-wrapper">
                <div className="row" style={{paddingTop : '80px'}}>
                    <div className="col-12 grid-margin">
                      
                      <div className="card">
                            <div className="card-body1">
                            <CSVErrorMessages errors={fileUploadingErrors}/>
                            
                            <h4>Onboarded Farmers</h4>
                            
                              <MaterialReactTable
                                columns={columns}
                                data={data}
                                enableClickToCopy = {true}
                               
                                selectAllMode = 'all'
                                muiTableProps={{
                                    sx: {
                                    border: '1px solid rgba(81, 81, 81, 1)',
                                    },
                                }}
                                muiTableHeadCellProps={{
                                    sx: {
                                    border: '1px solid rgba(81, 81, 81, 1)',
                                    },
                                }}
                                muiTableBodyCellProps={{
                                    sx: {
                                    border: '1px solid rgba(81, 81, 81, 1)',
                                    },
                                }}
                                enableEditing={userType && userType != 'viewer'}
                                onEditingRowSave={handleSaveRowEdits}
                                onEditingRowCancel={handleCancelRowEdits}
                                renderTopToolbarCustomActions={({table}) => (
                                    <div>
                                        {
                                            userType!='viewer' ? <><button 
                                            className='btn btn-sm btn-bluish-green  btn-icon-text'
                                            onClick={() => navigate('/add-onboarding')}
                                            variant="contained"
                                            >
                                            <i className='typcn typcn-plus btn-icon-prepend'></i> <span>New </span>
                                            </button>
                                                &nbsp;</> : null
                                        }
                                        <button
                                            className='btn btn-sm btn-primary  btn-icon-text'
                                            onClick={handleExportData}
                                            >
                                                <i className='typcn typcn-download btn-icon-prepend'></i> <span>Export CSV</span>  
                                        </button> &nbsp;
                                      
                                        
                                        {/* <label htmlFor="fileUpload" className="file-upload btn-sm btn-primary" style={{cursor : disabled === true ? 'auto' : 'pointer'}}><i className='typcn typcn-cloud-storage btn-icon-prepend' style={{fontSize : '16px'}}></i> Upload CSV
                                            <input id="fileUpload" style={{display : 'none'}} type="file" onInput={(event) => csvUploadHandler(event)} disabled={disabled}/>
                                        </label> */}
                                    </div>
                                )}
                                muiTablePaginationProps={{
                                    showFirstButton: true,
                                    showLastButton: true,  
                                }}
                                renderRowActions={({ row, table }) => (
                                    
                                    <div style={{ display: 'flex'}}>
                                        
                                        {
                                            userType && userType != 'viewer' ? 
                                            <button type="button" className="btn btn-success btn-sm btn-icon-text mr-3" style={{ width : '90px'}} onClick={()=>handleSaveRowEdits(row)}>
                                                Edit <i className="typcn typcn-edit btn-icon-append"></i>                                                                              
                                            </button> : ''
                                        }
                                        {
                                            userType && (userType==='admin' || userType==='super-admin') ? 
                                            <button type="button" className="btn btn-sm btn-danger btn-icon-text" style={{width : '90px'}} onClick={()=> handleCancelRowEdits(row)} disabled = {disabledIds.includes(row.original._id)}>
                                              {disabledIds.includes(row.original._id) === false ? <>Delete <i className="typcn typcn-trash btn-icon-append"></i></> : <>Deleting...</>}</button> : ''
                                        }
                                    </div>
                                )}
                                manualPagination={true}
                                onPaginationChange={setPagination}
                                manualFiltering = {true}
                                onColumnFiltersChange={setColumnFilters}
                                
                                state={{
                                    pagination,
                                    showProgressBars: isRefetching,
                                }}
                                rowCount={rowCount}
                                
                                onGlobalFilterChange = {setGlobalFilter}
                            />
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
            <NotificationContainer/>
        </div>
    );
}

export default FarmerData;